<template>
  <section class="section">
    <div class="container not-found-page">
      <p>Error 404 - Page not found</p>
    </div>
  </section>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'not-found-page',
  components: {},
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style lang="sass">
.not-found-page
  background-color: $white
</style>
